import React from "react";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Accounts = React.lazy(() => import("./pages/Accounts"));
const Wallet = React.lazy(() => import("./pages/Wallet"));
const WalletDetail = React.lazy(() => import("./pages/WalletDetails"));
const Transactions = React.lazy(() => import("./pages/Transaction"));
const Orders = React.lazy(() => import("./pages/Orders"));
const OrderDetails = React.lazy(() => import("./pages/OrderDetails"));
const Recycle = React.lazy(() => import("./pages/Recycle"));
const RecycleDetail = React.lazy(() => import("./pages/RecycleDetail"));
const Customers = React.lazy(() => import("./pages/Customers"));
const CustomerDetail = React.lazy(() => import("./pages/CustomerDetail"));
const Suppliers = React.lazy(() => import("./pages/Suppliers"));
const SupplierDetails = React.lazy(() => import("./pages/SupplierDetails"));
const Departments = React.lazy(() => import("./pages/Departments"));
const Stocks = React.lazy(() => import("./pages/Stocks"));
const Pincode = React.lazy(() => import("./pages/Pincode"));
const SizeCharts = React.lazy(() => import("./pages/SizeCharts"));

// PRODUCTS
const ProductList = React.lazy(() => import("./pages/ProductLists"));
const AddProduct = React.lazy(() => import("./pages/Products"));
const Categories = React.lazy(() => import("./pages/Category"));
const EditCategory = React.lazy(() => import("./pages/EditCategory"));
const ProductDetails = React.lazy(() => import("./pages/ProductDetails"));
const LinkedProduct = React.lazy(() => import("./pages/LinkedProduct"));
const ProductShipping = React.lazy(() => import("./pages/ProductShipping"));

const StoreLocator = React.lazy(() => import("./pages/StoreLocator"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const Notifications = React.lazy(() => import("./pages/Notification"));
const Coupons = React.lazy(() => import("./pages/Coupons"));
const EditCoupon = React.lazy(() => import("./pages/EditCoupon"));
const Pages = React.lazy(() => import("./pages/Pages"));
const AddNewPage = React.lazy(() => import("./pages/AddNewPage"));
const BlogsList = React.lazy(() => import("./pages/BlogsList"));
const AddNewBlog = React.lazy(() => import("./pages/AddNewBlog"));
const Login = React.lazy(() => import("./pages/Login"));
const Media = React.lazy(() => import("./pages/Media"));

// WEBSITES
const AnnouncementBar = React.lazy(() => import("./pages/AnnouncementBar"));
// MARKETING
const Audiences = React.lazy(() => import("./pages/Audiences"));

const routes = [
  {
    path: "/dashboard",
    isProtected: true,
    component: Dashboard,
    roles: ["007"],
  },
  {
    path: "/media/upload",
    isProtected: true,
    component: Media,
    roles: ["007"],
  },
  {
    path: "/media/upload/:id",
    isProtected: true,
    component: Media,
    roles: ["007"],
  },
  {
    path: "/accounts",
    isProtected: true,
    component: Accounts,
    roles: ["2000", "007"],
  },
  {
    path: "/wallet",
    isProtected: true,
    component: Wallet,
    roles: ["007"],
  },
  {
    path: "/wallet/:id",
    isProtected: true,
    component: WalletDetail,
    roles: ["007"],
  },
  {
    path: "/transactions",
    isProtected: true,
    component: Transactions,
    roles: ["007"],
  },
  {
    path: "/orders",
    isProtected: true,
    component: Orders,
    roles: ["007"],
  },
  {
    path: "/orders/:order_id",
    isProtected: true,
    component: OrderDetails,
    roles: ["007"],
  },
  {
    path: "/recycle/",
    isProtected: true,
    component: Recycle,
    roles: ["007"],
  },
  {
    path: "/recycle/:id",
    isProtected: true,
    component: RecycleDetail,
    roles: ["007"],
  },
  {
    path: "/customers",
    isProtected: true,
    component: Customers,
    roles: ["007"],
  },
  {
    path: "/customers/:id",
    isProtected: true,
    component: CustomerDetail,
    roles: ["007"],
  },
  {
    path: "/suppliers",
    isProtected: false,
    component: Suppliers,
    roles: ["007"],
  },
  {
    path: "/suppliers/:id",
    isProtected: false,
    component: SupplierDetails,
    roles: ["007"],
  },
  {
    path: "/departments",
    isProtected: false,
    component: Departments,
    roles: ["007"],
  },
  {
    path: "/stocks",
    isProtected: false,
    component: Stocks,
    roles: ["007"],
  },
  {
    path: "/pincode",
    isProtected: false,
    component: Pincode,
    roles: ["007"],
  },
  {
    path: "/products/:id",
    isProtected: true,
    component: AddProduct,
    roles: ["007"],
  },
  {
    path: "/products",
    isProtected: true,
    component: ProductList,
    roles: ["007"],
  },
  {
    path: "/product-details",
    isProtected: true,
    component: ProductDetails,
    roles: ["007"],
  },
  {
    path: "/linked-products",
    isProtected: true,
    component: LinkedProduct,
    roles: ["007"],
  },
  {
    path: "/product-shipping",
    isProtected: true,
    component: ProductShipping,
    roles: ["007"],
  },
  {
    path: "/categories",
    isProtected: true,
    component: Categories,
    roles: ["007"],
  },
  {
    path: "/categories/:id",
    isProtected: true,
    component: EditCategory,
    roles: ["007"],
  },
  {
    path: "/size-charts",
    isProtected: true,
    component: SizeCharts,
    roles: ["007"],
  },
  {
    path: "/store-locator",
    isProtected: false,
    component: StoreLocator,
    roles: ["007"],
  },
  {
    path: "/reviews",
    isProtected: false,
    component: Reviews,
    roles: ["007"],
  },
  {
    path: "/notifications",
    isProtected: false,
    component: Notifications,
  },
  {
    path: "/coupons",
    isProtected: true,
    component: Coupons,
    roles: ["007"],
  },
  {
    path: "/coupons/:id",
    isProtected: true,
    component: EditCoupon,
    roles: ["007"],
  },
  {
    path: "/pages",
    isProtected: false,
    component: Pages,
  },
  {
    path: "/pages/add",
    isProtected: false,
    component: AddNewPage,
  },
  {
    path: "/blogs",
    isProtected: false,
    component: BlogsList,
  },
  {
    path: "/blogs/add",
    isProtected: false,
    component: AddNewBlog,
  },
  {
    path: "/login",
    isProtected: false,
    component: Login,
  },
  {
    path: "/unauthorized",
    isProtected: false,
    component: Login,
  },
  {
    path: "/announcement-bar",
    isProtected: true,
    component: AnnouncementBar,
    roles: ["007"],
  },
  {
    path: "/audiences",
    isProtected: true,
    component: Audiences,
    roles: ["007"],
  },
];

export default routes;
