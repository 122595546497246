import { Card, Input, message, Button } from "antd";
import React, { useState, useEffect } from "react";
import { ArrowRightOutlined, SyncOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

export default function Login() {
  const { Search } = Input;
  const navigate = useNavigate();
  const [showOTP, setshowOTP] = useState(false);
  const [number, setnumber] = useState(0);
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    const inp = document.getElementById("otp1");
    if (inp) inp.focus();
  }, [showOTP]);

  const verifyNumber = (value) => {
    if (value.length <= 9 || value.length >= 11) {
      message.error("Please enter a valid number");
    } else {
      setshowOTP(true);
      sendOTP();
    }
  };

  const sendOTP = () => {
    const data = {
      number: number,
    };
    axios
      .post("/api/auth/generateOTP", data)
      .then((res) => {
        message.success(`OTP sent on ${number}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verifyOTP = () => {
    const otpInput = document.getElementsByClassName("otp-input");
    var otp = "";
    for (let i = 0; i < otpInput.length; i++) {
      otp += otpInput[i].value;
    }

    const data = {
      number: number,
      otp: otp,
    };

    axios
      .post("/api/auth/verifyOTP", data)
      .then((res) => {
        console.log(res);
        if (res.data.is_verified) {
          message.success("Verified user");
          setAuth({
            user: res.data.user,
          });
          navigate(from, { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.isError) {
          message.error(err.response.data.message);
        }
      });
  };

  function getCodeBoxElement(index) {
    return document.getElementById("otp" + index);
  }

  function onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    if (getCodeBoxElement(index).value.length === 1) {
      if (index !== 6) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
        // Submit code
        verifyOTP();
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
  }

  function onFocusEvent(index) {
    for (var item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  }

  return (
    <div className="flex-center full-height grey-bg">
      <div className="login-card">
        <Card>
          <h1 className="flex-center">Login</h1>
          <br />
          <div>Enter phone number</div>
          <Search
            placeholder="Enter number"
            enterButton={<ArrowRightOutlined />}
            size="large"
            type="number"
            onChange={(e) => setnumber(e.target.value)}
            onSearch={verifyNumber}
          />
          <br />
          {showOTP ? (
            <>
              <br />
              <div>Enter OTP</div>
              <input
                onKeyUp={(e) => onKeyUpEvent(1, e)}
                onFocus={onFocusEvent}
                maxLength={1}
                className="otp-input"
                id="otp1"
              />
              <input
                maxLength={1}
                onKeyUp={(e) => onKeyUpEvent(2, e)}
                onFocus={onFocusEvent}
                className="otp-input"
                id="otp2"
              />
              <input
                maxLength={1}
                onKeyUp={(e) => onKeyUpEvent(3, e)}
                onFocus={onFocusEvent}
                className="otp-input"
                id="otp3"
              />
              <input
                maxLength={1}
                onKeyUp={(e) => onKeyUpEvent(4, e)}
                onFocus={onFocusEvent}
                className="otp-input"
                id="otp4"
              />
              <input
                maxLength={1}
                onKeyUp={(e) => onKeyUpEvent(5, e)}
                onFocus={onFocusEvent}
                className="otp-input"
                id="otp5"
              />
              <input
                maxLength={1}
                onKeyUp={(e) => onKeyUpEvent(6, e)}
                onFocus={onFocusEvent}
                className="otp-input"
                id="otp6"
                style={{ marginRight: "0px" }}
              />
              <div className="flex-end resend-otp">
                <Button type="link" onClick={sendOTP}>
                  <SyncOutlined /> Resend OTP
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </Card>
      </div>
    </div>
  );
}
