import React, { Suspense } from "react";
import routes from "../route";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import PublicPages from "./PublicPages";
import PersistLogin from "./PersistLogin";

function MainContent() {
  const { pathname, location } = useLocation();
  if (pathname == "/") {
    return <Navigate to="/dashboard" />;
  }
  return (
    <main className="main-content">
      <Suspense fallback={"Loading"}>
        <Routes>
          {routes.map((mroute, idx) => {
            return (
              mroute.component && (
                <Route element={<PersistLogin />} key={idx}>
                  <Route
                    key={idx}
                    element={
                      mroute.isProtected ? (
                        <RequireAuth allowedRoles={mroute.roles} />
                      ) : (
                        <PublicPages />
                      )
                    }
                  >
                    <Route path={mroute.path} element={<mroute.component />} />
                  </Route>
                </Route>
              )
            );
          })}
          <Route
            path="*"
            element={
              <Navigate
                to="/page-not-found"
                state={{ from: location }}
                replace
              />
            }
          />
        </Routes>
      </Suspense>
    </main>
  );
}
export default MainContent;
