import React from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/SideBar";
import MainContent from "./components/MainContent";
import TopBar from "./components/TopBar";
import Login from "./pages/Login";
import Error404 from "./views/Error404";
import Unauthorized from "./pages/Unauthorized";

function App() {
  const { pathname } = useLocation();
  return (
    <div className="App">
      {pathname === "/login" ? (
        <Login />
      ) : pathname === "/page-not-found" ? (
        <Error404 />
      ) : pathname === "/unauthorized" ? (
        <Unauthorized />
      ) : (
        <>
          {/* <Layout> */}
          <Sidebar />
          {/* <Layout> */}
          <TopBar />
          <MainContent />
          {/* </Layout> */}
          {/* </Layout> */}
        </>
      )}
    </div>
  );
}

export default App;
