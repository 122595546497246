import React, { useEffect, useState } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Layout, Menu } from "antd";
import {
  PieChartOutlined,
  DropboxOutlined,
  ExportOutlined,
  FileDoneOutlined,
  ShoppingCartOutlined,
  RetweetOutlined,
  TeamOutlined,
  ReconciliationOutlined,
  BarChartOutlined,
  EnvironmentOutlined,
  CompassOutlined,
  ShopOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  BellOutlined,
  TagOutlined,
  CopyOutlined,
  FormOutlined,
  InboxOutlined,
  WalletOutlined,
  MonitorOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarLogo from "../assets/images/sidebar-logo.svg";
import "../css/sidebar.css";
import axios from "../api/axios";
const { SubMenu } = Menu;
const { Sider } = Layout;

function Sidebar() {
  const [openKeys, setOpenKeys] = useState([]);
  const [activeSideMenu, setactiveSideMenu] = useState("");
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    var pathname = location.pathname.split("/")[1];
    setactiveSideMenu(pathname);
  }, [location]);

  // submenu keys of first level
  const rootSubmenuKeys = ["menu-3", "menu-10"];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function handleMenuClick(e) {
    setactiveSideMenu(e.key);
  }

  const createProduct = (event) => {
    event.preventDefault();
    axios
      .get("/admin/product/create")
      .then((res) => {
        window.location.href = `/products/${res.data.id}`;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
      width={250}
      // trigger={null}
      // collapsible
      // collapsed={collapsed}
    >
      <div className="logo-container">
        <img className="logo-image" src={SidebarLogo} />
      </div>

      <Menu
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={handleMenuClick}
        selectedKeys={[activeSideMenu]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.ItemGroup key="g1" title="Menu">
          <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="media" icon={<CameraOutlined />}>
            <Link to="/media/upload">Media</Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="g2" title="Finance">
          <Menu.Item key="accounts" icon={<MonitorOutlined />}>
            <Link to="/accounts">Accounts</Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.Item key="wallet" icon={<WalletOutlined />}>
          <Link to="/wallet">Wallet</Link>
        </Menu.Item>

        <Menu.Item key="transactions" icon={<ExportOutlined />}>
          <Link to="/transactions">Transaction</Link>
        </Menu.Item>

        <Menu.ItemGroup key="g7" title="Marketing">
          <SubMenu
            key="abandoned_cart"
            icon={<ShoppingCartOutlined />}
            title="Abandoned cart"
          >
            <Menu.Item key="audiences">
              <Link to="/audiences">Audiences</Link>
            </Menu.Item>
          </SubMenu>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="g3" title="Ecommerce">
          <Menu.Item key="orders" icon={<FileDoneOutlined />}>
            <Link to="/orders">Orders</Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <SubMenu
          key="products_menu"
          icon={<ShoppingCartOutlined />}
          title="Products"
        >
          <Menu.Item key="products">
            <Link to="/products">All Products</Link>
          </Menu.Item>
          <Menu.Item key="product">
            <Link to="#" onClick={(e) => createProduct(e)}>
              Add New
            </Link>
          </Menu.Item>
          <Menu.Item key="linked-products">
            <Link to="/linked-products">Linked products</Link>
          </Menu.Item>
          <Menu.Item key="categories">
            <Link to="/categories">Categories</Link>
          </Menu.Item>
          <Menu.Item key="size-charts">
            <Link to="/size-charts">Size chart</Link>
          </Menu.Item>

          <SubMenu key="products-data" title="Meta Data">
            <Menu.Item key="product-details">
              <Link to="/product-details">Details</Link>
            </Menu.Item>
            <Menu.Item key="product-shipping">
              <Link to="/product-shipping">Shipping</Link>
            </Menu.Item>
          </SubMenu>
        </SubMenu>

        <Menu.Item key="recycle" icon={<RetweetOutlined />}>
          <Link to="recycle">Recycle</Link>
        </Menu.Item>

        <Menu.Item key="customers" icon={<TeamOutlined />}>
          <Link to="customers">Customers</Link>
        </Menu.Item>

        <Menu.Item key="coupons" icon={<TagOutlined />}>
          <Link to="coupons">Coupons</Link>
        </Menu.Item>

        <SubMenu
          key="inventory"
          icon={<ReconciliationOutlined />}
          title="Inventory"
        >
          <Menu.Item key="suppliers" icon={<TeamOutlined />}>
            <Link to="suppliers">Suppliers</Link>
          </Menu.Item>
          <Menu.Item key="departments" icon={<InboxOutlined />}>
            <Link to="departments">Departments</Link>
          </Menu.Item>
          <Menu.Item key="stocks" icon={<DropboxOutlined />}>
            <Link to="stocks">Stocks</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="menu-10" icon={<BarChartOutlined />} title="Reports">
          <Menu.Item key="orders-report">
            <Link to="orders-report">Orders</Link>
          </Menu.Item>
          <Menu.Item key="customers-reports">
            <Link to="customers-reports">Customers</Link>
          </Menu.Item>
        </SubMenu>

        <Menu.ItemGroup key="g3" title="Website">
          <SubMenu
            key="website_global"
            icon={<ShoppingCartOutlined />}
            title="Global"
          >
            <Menu.Item key="announcement-bar">
              <Link to="/announcement-bar">Announcement bar</Link>
            </Menu.Item>
          </SubMenu>
        </Menu.ItemGroup>

        {/* <p className="sidebar-heading">Location</p> */}
        <Menu.ItemGroup key="g4" title="Location">
          <Menu.Item key="pincode" icon={<EnvironmentOutlined />}>
            <Link to="pincode">Area of service</Link>
          </Menu.Item>
          <Menu.Item key="store-locator" icon={<CompassOutlined />}>
            <Link to="store-locator">Store locator</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        {/* <p className="sidebar-heading">Offline</p> */}
        <Menu.ItemGroup key="g5" title="Offline">
          <Menu.Item key="store" icon={<ShopOutlined />}>
            <Link to="store">Store</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        {/* <p className="sidebar-heading">Others</p> */}
        <Menu.ItemGroup key="g6" title="Others">
          <Menu.Item key="customer-support" icon={<CustomerServiceOutlined />}>
            <Link to="customer-support">Customer support</Link>
          </Menu.Item>
          <Menu.Item key="reviews" icon={<MessageOutlined />}>
            <Link to="reviews">Reviews</Link>
          </Menu.Item>
          <Menu.Item key="notifications" icon={<BellOutlined />}>
            <Link to="notifications">Notifications</Link>
          </Menu.Item>
          <Menu.Item key="pages" icon={<CopyOutlined />}>
            <Link to="pages">Pages</Link>
          </Menu.Item>
          <Menu.Item key="blogs" icon={<FormOutlined />}>
            <Link to="blogs">Blogs</Link>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </Sider>
  );
}

export default Sidebar;
